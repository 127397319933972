"use strict";

;(function (root, $) {
    var $toggleBtn, $nav, classNameOpen, CurrentWidth, $body;
    $toggleBtn = $(".js-main-menu-mobile-btn");
    $nav = $(".js-main-menu-nav");
    $body = $("body");

    if ($toggleBtn.length > 0 && $nav.length > 0) {
        classNameOpen = "open " + $nav.attr("data-className-open") || "open";

        $toggleBtn.click(function () {
            $nav.toggleClass(classNameOpen);

            if ($body.hasClass("g-overflow-hidden")) {
                $body.removeClass("g-overflow-hidden");
            } else {
                $body.addClass("g-overflow-hidden");
            }
        });

        CurrentWidth = root.MyThemeApp.CurrentWidthClass || root.CurrentWidthClass || null;
        if (CurrentWidth) {
            $(root).on("resize", function (event) {
                var callback = function callback() {
                    if (CurrentWidth.getCurrentWidth() > 749 && $nav.hasClass(classNameOpen)) {
                        $toggleBtn.click();
                        $nav.removeClass(classNameOpen);
                    }
                };
                setTimeout(callback, 1);
            });
        }
    }
})(window, jQuery);